import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Font } from 'three/examples/jsm/loaders/FontLoader';
import { PencilLinesPass } from "./PencilLinesPass";
import calendar from "./calendar.json";
import fontJson from "./font.json";
import * as cardsPng from '../static/cards/*.png';
import CNAME from '../static/CNAME';
import { damp, damp2, damp3, damp4, dampE, dampM, dampQ, dampS, dampC } from 'maath/easing'


console.log(CNAME);

class CardState {
  constructor(mesh: THREE.Mesh, startPosition: THREE.Vector3) {
    this.mesh = mesh;
    this.startPosition = startPosition;
  }
  is_selected: boolean = false;
  is_flipped: boolean = false;
  mesh: THREE.Mesh;
  startPosition: THREE.Vector3;
}

const isPhone = window.innerWidth / window.devicePixelRatio < 600;

const scene = new THREE.Scene();
const clock = new THREE.Clock();
const aspect = window.innerWidth / window.innerHeight;
const camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
camera.position.z = 5;
camera.position.y = 0;
camera.position.x = 0;

function getRandomCoordinatesInShell(outerRadius: number, innerRadius: number): { x: number, y: number, z: number } {
  let x, y, z, r;
  do {
    const u = Math.random();
    const v = Math.random();
    const theta = 2 * Math.PI * u;
    const phi = Math.acos(2 * v - 1);
    r = outerRadius * Math.cbrt(Math.random());

    x = r * Math.sin(phi) * Math.cos(theta);
    y = r * Math.sin(phi) * Math.sin(theta);
    z = r * Math.cos(phi);
  } while (r < innerRadius);

  return { x, y, z };
}

function generateRandomBackgroundSquares(scene: THREE.Scene, count: number, outerRadius: number, innerRadius: number) {
  const box = new THREE.BoxGeometry();
  const cubes = [];
  for (let i = 0; i < count; i++) {
    const object = new THREE.Mesh(
      box,
      new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff })
    );

    const { x, y, z } = getRandomCoordinatesInShell(outerRadius, innerRadius);
    object.position.x = x;
    object.position.y = y;
    object.position.z = z;

    object.rotation.x = Math.random() * 2 * Math.PI;
    object.rotation.y = Math.random() * 2 * Math.PI;
    object.rotation.z = Math.random() * 2 * Math.PI;

    object.scale.x = Math.random() + 0.8;
    object.scale.y = Math.random() + 0.8;
    object.scale.z = Math.random() + 0.8;

    scene.add(object);
    cubes.push(object);
  }
  return cubes;
}


function generateTitle(scene: THREE.Scene) {
  const font = new Font(fontJson);
  const title = new TextGeometry(
    "mosaic",
    {
      font: font,
      size: 1.2,
      height: 0,
      depth: 0.1,
      curveSegments: 50,
    }
  );
  title.center();
  title.computeBoundingBox();

  const titleMesh = new THREE.Mesh(title, new THREE.MeshBasicMaterial());
  titleMesh.position.x = 0;
  titleMesh.position.y = 3.5;
  titleMesh.position.z = 1;
  scene.add(titleMesh);
  return titleMesh;
}

function generateGridOfCards(scene: THREE.Scene, cardWidth = 1.1, cardHeight = 1.1, spacing = 0.5) {
  const totalCards = calendar.length;

  // total cards in rows of 3 for mobile, 5 for desktop
  const cols = isPhone ? 3 : 5;
  const rows = Math.ceil(totalCards / cols);

  const cards: CardState[] = [];
  const entries = calendar.map(entry => entry.card).reverse();
  const loader = new THREE.TextureLoader();

  // Adjust starting position based on device type - making more subtle adjustments
  const mobileOffset = isPhone ? -6 : -4;
  
  // Adjust card width and spacing for desktop to accommodate more columns
  const adjustedCardWidth = isPhone ? cardWidth : cardWidth * 0.85;
  const adjustedCardHeight = isPhone ? cardHeight : cardHeight * 0.9;
  const adjustedSpacing = isPhone ? spacing : spacing * 0.8;
  
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      if (i * cols + j >= totalCards) continue;
      const index = i * cols + j;
      const entry = entries[index];
      const geometry = new THREE.BoxGeometry(adjustedCardWidth, adjustedCardHeight, 0.01);
      // Improve texture quality with anisotropic filtering
      const face = loader.load(cardsPng[entry + '_faceout'] || cardsPng[entry + '_titlecard_V2']);
      if (face.anisotropy !== undefined) {
        face.anisotropy = 16;
      }
      const back = loader.load(cardsPng[entry + '_titlecard_V2']);
      if (back.anisotropy !== undefined) {
        back.anisotropy = 16;
      }
      const frontMaterial = new THREE.MeshBasicMaterial({ map: face ? face : back });
      const backMaterial = new THREE.MeshBasicMaterial({ map: back });
      const blankMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });

      const materials = [
        blankMaterial, // Right side
        blankMaterial, // Left side
        blankMaterial, // Top side
        blankMaterial, // Bottom side
        frontMaterial, // Front side
        backMaterial   // Back side
      ];

      const card = new THREE.Mesh(geometry, materials);

      // cards need to start further below the title
      const topValue = mobileOffset + (rows * (adjustedCardHeight + adjustedSpacing)) / 2;
      card.position.set(
        j * (adjustedCardWidth + adjustedSpacing) - (cols * (adjustedCardWidth + adjustedSpacing)) / 2 + adjustedCardWidth / 2,
        topValue - (i * (adjustedCardHeight + adjustedSpacing)), // Arrange cards downwards starting from topValue
        0
      );
      scene.add(card);
      cards.push(new CardState(card, card.position.clone()));
    }
  }

  return cards;
}

const cubes = generateRandomBackgroundSquares(scene, 3500, 45, 12);
generateTitle(scene);
const cards = generateGridOfCards(scene);

const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor("#eee");
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.CineonToneMapping;
renderer.toneMappingExposure = 1;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
document.body.appendChild(renderer.domElement);

const composer = new EffectComposer(renderer);
const renderPass = new RenderPass(scene, camera);
const pencilLinePass = new PencilLinesPass({
  width: renderer.domElement.clientWidth,
  height: renderer.domElement.clientHeight,
  scene,
  camera,
});

composer.addPass(renderPass);
composer.addPass(pencilLinePass);
let orbitAngle = 0; // Angle for orbiting cubes
const orbitSpeed = 0.001; // Speed of orbiting
function updateCubePositions() {

  cubes.forEach((cube) => {
    const initialPosition = cube.position;

    const radius = Math.sqrt(initialPosition.x ** 2 + initialPosition.y ** 2 + initialPosition.z ** 2);
    const theta = Math.acos(initialPosition.z / radius);
    const phi = Math.atan2(initialPosition.y, initialPosition.x) + orbitSpeed; // Azimuthal angle with orbit

    const offsetX = radius * Math.sin(theta) * Math.cos(phi);
    const offsetY = radius * Math.sin(theta) * Math.sin(phi);
    const offsetZ = radius * Math.cos(theta);

    cube.position.x = offsetX;
    cube.position.y = offsetY;
    cube.position.z = offsetZ;
  });

  orbitAngle += orbitSpeed;
}

function animateCards() {
  const delta = clock.getDelta()

  // move selected card to the front
  const selectedCard = cards.find(card => card.is_selected);
  if (selectedCard) {
    const cameraPosition = camera.position.clone();
    const cameraDirection = new THREE.Vector3();
    camera.getWorldDirection(cameraDirection);

    // Calculate a position in front of the camera
    const fov = camera.fov * (Math.PI / 180); // Convert FOV to radians
    const distanceFromCamera = (1 / 2) / Math.tan(fov / 2) + 0.5; // Calculate distance to fit the card
    const targetPosition = cameraPosition.add(cameraDirection.multiplyScalar(distanceFromCamera));
    damp3(selectedCard.mesh.position, targetPosition, 0.25, delta)

    // orientate card towards camera
    const targetRotation = new THREE.Euler();
    targetRotation.setFromQuaternion(camera.quaternion);
    if (selectedCard.is_flipped) {
      targetRotation.y += Math.PI;
    }
    dampE(selectedCard.mesh.rotation, targetRotation, 0.25, delta)


  } else {
    cards.forEach(card => {
      if (card.is_selected) return;
      damp3(card.mesh.position, card.startPosition, 0.25, delta)
      dampE(card.mesh.rotation, [0, 0, 0], 0.25, delta)
    });
  }
}


const controls = new OrbitControls(camera, renderer.domElement);
const keysPressed = { w: false, a: false, s: false, d: false, q: false, e: false };
function animate() {

  updateCubePositions();
  animateCards();

  requestAnimationFrame(animate);
  const direction = new THREE.Vector3();
  camera.getWorldDirection(direction);
  if (keysPressed.w) {
    camera.position.addScaledVector(direction, 0.1);
  }
  if (keysPressed.s) {
    camera.position.addScaledVector(direction, -0.1);
  }
  if (keysPressed.a) {
    const right = new THREE.Vector3();
    camera.getWorldDirection(right);
    right.cross(camera.up).normalize();
    camera.position.addScaledVector(right, -0.1);
  }
  if (keysPressed.d) {
    const right = new THREE.Vector3();
    camera.getWorldDirection(right);
    right.cross(camera.up).normalize();
    camera.position.addScaledVector(right, 0.1);
  }
  if (keysPressed.q) {
    camera.position.y -= 0.1;
  }
  if (keysPressed.e) {
    camera.position.y += 0.1;
  }
  composer.render();
}

window.addEventListener("resize", onWindowResize);

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}


let isHandling = false;
function onHandleInteraction(event) {
  if (isHandling) return;
  try {
    isHandling = true;
    var raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();

    if (event instanceof MouseEvent) {
      // Handle mouse event
      pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
      pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;
    } else if (event instanceof TouchEvent) {
      // Handle touch event
      const touch = event.touches[0];
      pointer.x = (touch.clientX / window.innerWidth) * 2 - 1;
      pointer.y = - (touch.clientY / window.innerHeight) * 2 + 1;
    }

    raycaster.setFromCamera(pointer, camera);

    const intersects = raycaster.intersectObjects(cards.map(card => card.mesh));
    if (intersects.length > 0) {
      const selectedCard = intersects[0].object;
      const cardState: CardState | undefined = cards.find(card => card.mesh === selectedCard);
      if (!cardState) return;

      const currentSelectedCard = cards.find(card => card.is_selected);
      if (currentSelectedCard && currentSelectedCard !== cardState) {
        return;
      }

      if (!cardState.is_selected) {
        cardState.is_selected = true;
      } else if (!cardState.is_flipped) {
        cardState.is_flipped = true
      } else {
        cardState.is_selected = false;
        cardState.is_flipped = false;
      }
    }

  } finally {
    isHandling = false;
  }
}

window.addEventListener('mousedown', onHandleInteraction);
window.addEventListener('touchstart', onHandleInteraction);
animate();

if (isPhone) {
  camera.position.z = 8;
  camera.position.y = 1; // Adjust camera up a bit to see the title on mobile
} else {
  camera.position.z = 6.5; // Move camera back more to see the wider grid
  camera.position.y = 1.5; // Adjust camera up to see the title on desktop
}
// WASD controls

function onKeyDown(event: KeyboardEvent) {
  switch (event.key) {
    case 'w':
      keysPressed.w = true;
      break;
    case 'a':
      keysPressed.a = true;
      break;
    case 's':
      keysPressed.s = true;
      break;
    case 'd':
      keysPressed.d = true;
      break;
    case 'q':
      keysPressed.q = true;
      break;
    case 'e':
      keysPressed.e = true;
      break;
  }
}

function onKeyUp(event: KeyboardEvent) {
  switch (event.key) {
    case 'w':
      keysPressed.w = false;
      break;
    case 'a':
      keysPressed.a = false;
      break;
    case 's':
      keysPressed.s = false;
      break;
    case 'd':
      keysPressed.d = false;
      break;
    case 'q':
      keysPressed.q = false;
      break;
    case 'e':
      keysPressed.e = false;
      break;
  }
}

window.addEventListener('keydown', onKeyDown);
window.addEventListener('keyup', onKeyUp);
